.chat-container {

    // min-height: 100vh;
    border-radius: 5px;
    background: white;
    padding: 2px;

    .users-box {
        background: white;
        height: 100%;
        overflow: auto;
        border-right: 1px solid #dbdbdb;
    }

    .box-header {
        border-bottom: 1px solid #e7e7e7;


        .header-top {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .message-tabs {
            display: flex;

            .tab {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 5px;
                flex: 1;
                text-align: center;
                padding: 10px;
                cursor: pointer;

                &.active {
                    border-bottom: 2px solid var(--color-primary);
                    color: var(--color-primary);
                }
            }

            @media (max-width:768px) {
                font-size: 15px;
            }
        }

        .message-search-box {
            padding: 10px;
            display: flex;
            align-items: center;
            position: relative;
        }

        .message-search-input {
            flex-grow: 1;
            padding: 10px 30px;
            background: #F6F6F6;
            border: none;
            font-size: 15px;
            border-radius: 4px;

            &:focus {
                outline: 2px solid var(--color-primary);
            }
        }

        .message-search-box {
            svg {
                position: absolute;
                left: 18px;
            }


            .clear-message {
                right: 18px;
                left: unset;
            }
        }

    }

    .box-title {
        font-weight: 500;
        margin: 0;
    }

    .new-chat {
        font-size: 23px;
        cursor: pointer;
    }

    .new-chat-box {
        .users-list {
            .item {
                display: flex;
                gap: 10px;
                cursor: pointer;
                padding: 10px 0 0px 10px;
            }

            .username {
                font-weight: 500;
            }

            .user-meta {
                color: rgba(0, 0, 0, 0.6);
                font-size: 12px;
                font-weight: 400;
            }

            .user-details {
                flex: 1;
                border-bottom: 1px solid #e7e7e7;
                padding-bottom: 5px;
            }

            .item:hover {
                background: #e7e7e7;
            }

            .item:hover .user-details {
                border-color: #cbcbcb;
            }
        }
    }

    ul.users-list {
        list-style: none;
        padding: 0;
        max-height: 350px;
        overflow: auto;
        margin: 0;

        li {
            padding: 20px;
            display: flex;
            gap: 12px;
            align-items: center;
            cursor: pointer;

            &.active {
                background: #d3a11f30;
            }

            img {
                border-radius: 50%;
                object-fit: cover;
            }

            .user-message {
                font-size: 13px;
                color: rgb(125, 129, 132);
            }


            .username {
                color: rgb(12, 20, 31);
                font-size: 15px;
                font-weight: 500;
            }


            .message-time {
                color: rgb(125, 129, 132);
                font-size: 10px;
                display: flex;
                align-items: center;

                &.unread:after {
                    content: "";
                    width: 7px;
                    height: 7px;
                    border-radius: 10px;
                    background: var(--color-primary);
                    display: block;
                    margin-left: 7px;
                }
            }

            .user-details {
                flex-grow: 1;
            }
        }

    }

    .chat-box {
        background: #fff;
        position: relative;

        .chat-mobile-top {
            padding: 10px;
            align-items: center;
            display: flex;
            gap: 10px;
            border-bottom: 1px solid rgba(140, 140, 140, 0.2);
            box-shadow: 0px 1px 0px rgba(140, 140, 140, 0.2);
        }

        .name {
            font-size: 16px;
            font-weight: 500;
        }

    }

    .chat-area {
        overflow: auto;
        // height: 100%;
        padding: 20px 15px 40px 20px;
        height: 318px;
    }


    .chat-item {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;

        &.self {
            margin-left: auto;

            .details {
                text-align: right;
            }
        }

        .chat-avatar {
            border-radius: 50px;
            object-fit: cover;
        }

        .sender {
            font-weight: 500;
        }

        .text {
            margin-top: 10px;
            font-size: 15px;

            .message_attachments {
                display: flex;

                a {
                    margin-right: 10px;
                    width: 100px;
                    height: 100px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }

        span.time {
            font-size: 13px;
            font-weight: 400;
            color: rgb(125, 129, 132);
            margin-left: 8px;
        }
    }

    .new-chat-box {
        .title {
            padding: 15px;
            font-weight: 500;
            border-bottom: 1px solid #e7e7e7;
        }

        .search-users .form-control {
            margin: auto;
            border: 2px solid white;
            border-radius: 30px;
            transition: all 0.2s ease;
            font-size: 15px;
        }

        .search-users {
            padding: 5px 15px;
            border-bottom: 1px solid #e7e7e7;
        }

        .search-users .form-control:focus {
            border: 2px solid;
        }

    }

    .chat-footer {
        display: flex;
        padding-bottom: 20px;
        width: 100%;
        border-top: 1px solid gainsboro;
        flex-wrap: wrap;
        position: relative;

        .message-box {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 10px 15px;

            input.message {
                border-radius: 50px;
                border: none;
            }

            .user-avatar img {
                border-radius: 50%;
                object-fit: cover;

            }

            .message-input {
                flex: 1;
                max-width: 100%;
            }

            .message {
                min-height: 100px;
                max-width: 100%;
                background: rgb(243, 242, 240);
                padding: 10px;
                border-radius: 5px;
                font-size: 15px;
            }

            [contenteditable=true]:empty:before {
                color: #9b9999;
            }

            .message:focus {
                box-shadow: none;
            }
        }

        aside.EmojiPickerReact.epr-main {
            position: absolute;
            top: 0;
            --epr-emoji-fullsize: 35px;
        }

        .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
            font-size: 15px;
        }


        .EmojiPickerReact .epr-search-container input.epr-search {
            font-size: 13px;
        }

        .message-actions {
            border-top: 1px solid gainsboro;
            display: flex;
            width: 100%;
            padding: 20px 15px 0px;
            font-size: 20px;
            color: #646363;
            align-items: center;

            svg {
                cursor: pointer;
                border-radius: 50px;
                padding: 10px;
                box-sizing: content-box;

                &:hover {
                    background: #f1f1f1;
                }
            }

            .send-message {
                font-size: 20px;
                flex: 1;
                text-align: right;

                button {
                    background-color: var(--color-primary);
                    color: white;
                }
            }



        }

        .attachments {
            padding: 10px 15px;
            /* border-top: 1px solid gainsboro; */
            width: 100%;
            display: flex;
            gap: 10px;

            .item img {
                width: 40px;
                height: 40px;
                border-radius: 10px;
            }
        }


    }

    @media (max-width:768px) {
        .mobile-hide {
            display: none;
        }
    }
}