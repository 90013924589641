.agency-page-creative-shortlist {
    .candidate-list {
        background-color: #fff;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        border: 1px solid #ECEDF2;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        margin-bottom: 30px;
        padding: 30px;
    }

    .candidate-info {
        flex: 1;
    }

    .candidate-logo {
        float: left;
        width: 90px;
        height: 90px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .candidate-info-content {
        padding-left: 20px;
        width: calc(100% - 90px);
    }

    .title-wrapper>* {
        display: inline-block;
        vertical-align: text-bottom;
        margin-bottom: 0;
        margin-left: 5px;
    }


    .candidate-title {
        font-size: 18px;
        margin: 0;
        font-weight: 400;
    }

    span.featured {
        display: inline-block;
        text-align: center;
        font-size: 11px;
        line-height: 25px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        background-color: #34A853;
        color: #fff;
    }



    .job-metas>div {
        display: inline-block;
        margin-right: 25px;
    }

    .action-button a {
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 30px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        display: inline-block;
        background-color: #ededed;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        font-size: 12px;
        position: relative;
    }

    .action-button {
        display: flex;
        gap: 10px;
    }

    @media (max-width: 650px){
        .ali-right{
            margin-left: 123px;
            margin-top: 10px;
        }
    }
}