.community-title {
    color: white;
    font-family: 'Alta';
    font-size: 61px;
    text-align: center;
    font-weight: bold;
}

.community-subtitle {
    font-size: 20px;
    color: white;
    text-align: center;
    font-weight: 400;
}

.post-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
}

.status-box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-avatar img {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}

.status-box .textarea {
    flex: 1;
}

.status-input {
    background: rgb(250, 250, 250);
    border-radius: 50px;
    resize: none;
    border: none;
    font-size: 16px;
}

.status-input:focus {
    box-shadow: none;
    border: 2px solid var(--color-primary);
}

.divider {
    width: 100%;
    height: 1px;
    background: #dddddd;
    margin: 20px 0;
}

.status-options {
    display: flex;
    gap: 15px;
}

.status-options .item {
    font-size: 20px;
    padding: 6px;
    border-radius: 8px;
    transition: all 0.2s ease;
    display: flex;
}

.status-options .item:hover {
    background: #d3a11fab;
}


.wall-options {
    background: #eeeeee;
    border-radius: 8px 8px 0 0;
    padding: 15px 25px;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    gap: 10px;
}

.community-sidebar {
    background: white;
    border-radius: 5px;
}

.sidebar-menu {
    padding: 10px;
}

.sidebar-menu .menu-container {
    list-style: none;
    padding: 0;
}

.sidebar-menu li.menu-item {
    margin-bottom: 10px;
}

.sidebar-menu li.menu-item a {
    align-items: center;
    display: flex;
    gap: 5px;
    text-decoration: none;
    color: black;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.2s ease-in;
}

.sidebar-menu li.menu-item:hover a,
.sidebar-menu li.menu-item a.active {
    background: var(--color-primary);
    color: white;
}

.menu-item .count {

    background: var(--color-primary);
    border-radius: 20%;
    padding: 2px 6px;
    margin-left: 7px;
    font-size: 10px;
    display: inline-block;
    color: white;
}

.postlist {
    margin: 30px 0;
}

.post-item {
    background: white;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 30px;
}

.post-header {
    display: flex;
    gap: 10px;
    align-items: center;
}

img.post-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.post-meta {
    flex: 1;
}

.post-username {
    font-weight: 500;
}

.post-time {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: rgb(125, 129, 132);
    font-size: 10pt;
    align-items: center;
}

.post-time svg {
    font-size: 14pt;
    margin-right: 5px;
}

.post-time span {
    font-size: 10pt;
}

.post-action {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    font-weight: 500;
    background: rgb(249 246 246);
    border-radius: 16px;
    padding: 6px 8px 6px 8px;
    position: relative;
    user-select: none;
    -moz-user-select: none;
}

.post-action.post-comments:hover {
    color: #d3a11fab;
}

.post-action.post-comments.active {
    color: #d3a11fab;
}

.post-action.post-comments.active:hover {
    color: #000;
}

.post-action.post-likes:hover {
    color: #d3a11fab;
}

.post-action.post-likes.active {
    color: #d3a11fab;
}

.post-action.post-likes.active:hover {
    color: #000;
}

.postmodal-header {
    position: relative;
}

.post-modal-close {
    position: absolute;
    right: 0px;
    top: 0px;
    color: #000;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
}

.post-modal-close:hover {
    color: #d3a11fab;
}

.post-modal-close svg {
    width: 2em;
    height: 2em;
}

.action-dropdown ul {
    list-style: none;
    border: 1px solid #f1f1f1;
    padding: 10px;
    position: absolute;
    background: white;
    border-radius: 5px;
    width: 120px;
    font-size: 15px;
}

.action-dropdown li:hover {
    color: var(--color-primary);
}

.action-dropdown {
    transition: all 0.3s ease;
    position: absolute;
    left: calc(calc(100% + 75px) * -1);
    top: 15px;
}

.post-content {
    margin-top: 20px;
}

.post-content .post-body {
    font-size: 14pt;
}

.post-body img {
    border-radius: 5px;
    width: 100%;
}

.post-actions {
    position: relative;
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.post-action svg {
    font-size: 18px;
}

.post-action svg path {
    stroke-width: 50px;
}

.post-action:hover {
    background: rgb(239, 240, 240);
}


.comment-input>div:empty:before {
    content: attr(placeholder);
    display: block;
    color: #858585;
}

.comment-input>div {
    color: rgb(13, 26, 38);
    font-weight: 400;
    padding: 6px 12px;
    background: rgb(250, 250, 250);
    border-radius: 50px;
    resize: none;
    border: none;
    font-size: 17px;
    cursor: text;
    border: 2px solid white;
}

.comment-input>div:focus-visible {
    outline: none;
    border: 2px solid var(--color-primary);
}

.comment-input {
    margin-bottom: 20px;
}

.title-small {
    font-size: 16px;
}

.comment {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.comment-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.comment-meta p.username {
    margin: 0;
    color: black;
    height: 45px;
    display: flex;
    align-items: center;
}

.comment-meta p.username a {
    color: black;
    font-family: 'Jost', Arial, sans-serify;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}

.comment-meta p.username a:hover {
    color: var(--color-primary);
}

.comment-meta div.content {
    color: #6c6c6c;
    font-weight: 400;
    font-size: 14pt;
    line-height: 24px;
}

.comments {
    margin-left: 25px;
    margin-top: 25px;
    position: relative;
}

.reply-section {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    background: rgb(249 246 246);
    border-radius: 16px;
    padding: 5px 15px;
    width: -moz-fit-content;
    width: fit-content;
    transition: all 0.2s ease;
}

.reply-section:hover {
    background: rgb(239, 240, 240);
}

.all-comments-button {
    text-align: center;
    color: var(--color-primary);
    font-size: 15px;
    transition: all 0.2s ease;
    cursor: pointer;
    margin: 10px;
}

.all-comments-button span:hover {
    background: var(--color-primary);
    color: white;
}

.all-comments-button span {
    padding: 5px 10px;
    border-radius: 10px;
    transition: all 0.2s ease;
}

.restricted-creatives-only {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100px;
    margin-top: 50px;
}

.restricted-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 20px;
    background-color: #fff;
    color: var(--color-primary);
    border-radius: 10px;
}

.restricted-message h4 {
    text-align: center;
}

.restricted-message h5 {
    text-align: center;
    color: #000;
}

.sliding-message {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    min-width: 300px;
    height: 60px;
    background-color: #000;
    color: var(--color-primary);
    border-radius: 10px;
    padding: 20px 30px;
    font-weight: 600;
    font-size: medium;
    font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    top: 200px;
    right: -100%;
    transition: right 1s;
    z-index: 99;
}

.sliding-message.active {
    right: 20px;
}

.num-unit {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    padding-top: 1px;
    width: 20px;
    height: 20px;
    background-color: gray;
    color: #fff;
    border: 1px solid gray;
    border-radius: 100%;
}

.num-unit:hover {
    background-color: var(--color-primary);
    color: #000;
    border: 1px solid var(--color-primary);
}

.post-actions .post-liked-by-dropdown {
    position: absolute;
    top: -50px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    padding: 5px;
    border: 1px solid lightgray;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    min-width: max-content;
    min-height: 45px;
}

.post-actions .post-liked-by-dropdown .liked-by img {
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;    
}


.post-actions .post-liked-by-dropdown .liked-by img {
    border: 2px solid lightgray;
    flex: 0;
}

.post-actions .post-liked-by-dropdown .liked-by img:hover {
    border: 2px solid var(--color-primary);
}

.post-actions .post-liked-by-dropdown .total-likes {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-top: 3px;
    margin-left: 5px;
    font-weight: 500;
    color: gray;
    font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.post-actions .post-liked-by-dropdown .total-likes:hover {
    color: var(--color-primary);
}

.comments .comment {
    position: relative;
}
.comments .comment .post-action.comments {
    position: absolute;
    top: -10px;
    right: 0px;
}

.user-slug {
    color: #000;
}

.user-slug:hover {
    color: var(--color-primary);
}

.postmodal-footer {
    display: flex;
    flex-direction: row;
}

.postmodal-footer .postmodal-offensive-words {
    margin-right: 10px;
    flex: 1;
}
.postmodal-footer .postmodal-offensive-words .message {
    padding: 10px;
    border-radius: 5px;
    background-color: pink;
    color: darkred;
    font-family: "JOST", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14pt;
    font-weight: 500;
    line-height: 1;
}

.postmodal-footer .postmodal-action {
    flex: 0;
    align-self: flex-end;
}

.postmodal-editor {
    padding: 10px;
    border: 1px solid lightgray;
    width: 100%;
    height: 100%;
}

.postmodal-editor:focus {
    border: 1px solid darkgray;
}

.back-to-top {
    color: var(--color-primary);
    text-align: right;
}

.back-to-top svg {
    width: 50px;
    height: 50px;
}

.group-visibility {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin: 10px 0px;
}

.group-visibility select {
    width: 150px;
    padding: 10px;
    border: 1px solid lightgray;
}

.group-visibility select:focus {
    border: 1px solid gray;
}

.group-image-to-upload {
    width: 75px;
    min-width: 75px;
    max-width: 75px;
    height: 75px;
    min-height: 75px;
    max-height: 75px;
    object-fit: cover;
    border: 2px solid var(--color-primary);
}

.groups-header {
    display: flex;
}

.friends-header .post-form,
.groups-header .post-form  {
    display: flex;
    column-gap: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.edit-group .postmodal-action {
    display: flex;
    column-gap: 10px;
}

