.main {
    /* margin-bottom: 50px; */
}

h1.bannerHeading {
    font-size: 61px;
    margin: 0;
    color: white;
    font-weight: 400;

}

.banner {
    text-align: center;
    background: black;
    padding: 50px 0 0;
    /* min-height: 95vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

p.subHeading {
    font-size: 20px;
    margin: 0px;
    color: white;
}

.searchArea {
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
}

p.searchHeader {
    color: rgb(211, 161, 31);
    font-size: 26px;
    margin: 0;
}

.popularSearch p {
    font-size: 20px;
    margin: 0px;
    font-weight: 300;
    color: white;
}

.popularSearch a {
    color: white;
    text-decoration: none;
}

.popularSearch {
    margin-top: 5px;
}

input.searchInput {
    padding-left: 45px;
    height: 60px;
    box-sizing: border-box;
    font-family: 'JOST';
    border: none;
    font-weight: 300;
    font-size: 20px;
    color: black;
    width: 100%;
}

input.searchInput:focus {
    box-shadow: none
}

.searchBox {
    border: 1px solid;
    border-radius: 8px;
    text-align: left;
    padding: 5px;
    background: white;
}

input.searchInput::placeholder {
    color: black;
}

button.searchBtn {
    background: rgb(119, 119, 119);
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 8px;
    letter-spacing: 3px;
    border: none;
    width: 100%;
    height: 100%;
}

button.searchBtn:hover {
    background: rgb(211, 161, 31);
    color: black;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

button.searchBtn {
    background: rgb(119, 119, 119);
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 8px;
    letter-spacing: 3px;
    border: none;
    width: 100%;
    height: 100%;
}

button.searchBtn:hover {
    background: rgb(211, 161, 31);
    color: black;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.searchIcon {
    position: absolute;
    top: 16px;
    left: 20px;
}

.featureBox * {
    display: block;
}

.featureImg {
    margin: auto;
}

.featureTitle {
    background: var(--color-primary);
    color: black;
    font-size: 23px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    padding: 7px;
    width: 200px;
    margin: 10px auto;
    transition: all 0.3s ease;
}

.featureTitle:hover {
    background: rgb(119, 119, 119);
    color: white;
}

.featureDesc {
    color: white;
    font-size: 20px;
    font-weight: 300;
}

.about-content h5 {
    font-size: 26px;
    font-weight: 400;
    margin-top: 7px;
    margin-bottom: 0;
}

.section.about h1 {
    font-weight: 400;
}

.section {
    padding-top: 36px;
}

.featureBox:hover .featureImg {
    -webkit-animation: updown .8s ease-in-out 0s infinite;
    animation: updown .8s ease-in-out 0s infinite;
}

@keyframes updown {

    50% {
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    0%,
    100% {
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@media (max-width:600px) {
    h1.bannerHeading {
        font-size: 50px;
        margin: 20px 0;
    }
}

/* Creative Section Styles */

.creative-section {
    background: black;
    /* margin-bottom: 50px; */
    padding-top: 20px;
}

.creative-section .sectionHeader {
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;
    border: 3px solid white;
    flex-wrap: wrap;
}

.creative-section .sectionTitle {
    font-size: 36px;
    font-weight: 400;
}

.browseAll {
    font-size: 20px;
    letter-spacing: 3px;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
    color: white;
}

.browseAll:hover {
    color: rgb(211, 161, 31) !important
}

.browseAll>a {
    text-decoration: none;
    color: white;
}

.sectionContent {
    margin-top: 20px;
    padding: 0 50px 20px;
}


img.candidateLogo {
    width: 90px;
    height: 90px;
    margin: 0 auto 15px;
    border-radius: 50%;
    object-fit: cover;
}

swiper-slide {
    text-align: center;
}

img.sliderImage {
    width: 200px;
    background: black;
    margin: auto;
}

swiper-slide {
    text-align: center;
}


.sliderContent {
    position: relative;
    text-align: center;
    background: white;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    animation: fade-in .3s linear;
    height: 100%;
}

.agencies-slider {
    min-height: 380px;

}

.agencyName {
    font-size: 18px;
}

.position {
    font-size: 16px;
}

.location {
    display: flex;
    align-items: center;
    flex: 1;
}

.location svg {
    margin-right: 5px;
}

.location>* {
    font-size: 20px;
    color: rgb(105, 105, 105);
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    text-decoration: none;
}

.profileLink {
    background: black;
    color: white;
    text-decoration: none;
    font-size: 20px;
    width: 90%;
    border-radius: 8px;
    display: flex;
    margin: 20px auto 0;
    bottom: 40px;
    height: 60px;
    justify-content: center;
    align-items: center;
}

.profileLink:hover {
    background-color: #D9D9D9;
}

.profileLink:hover a {
    color: #D3A11F !important;
}

.profileLink a {
    color: white;
    text-decoration: none;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}


/* Spotlight Section */

.spotlight-slider {
    min-height: 260px;
}

img.spotlight-image {
    width: 40%;
    height: auto;
}

.spotlight-slider .date {
    font-size: 14px;
    color: rgb(105, 105, 105);
    font-weight: 300;
}

.spotlight-meta {
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: black;
    margin-top: 18px;
    color: rgb(211, 161, 31);
}

.spotlight-meta:hover {
    color: #000;
}

.watch-link {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    position: relative;
    color: black
}

.watch-link>* {
    font-size: 14px;
    font-weight: 300;
    color: black;
    text-decoration: none;
}

.sectionContent.full-width {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 0px;
    margin: 20px 0;
}

.sliderContent.spotlight-slider {
    padding: 10px;
}

.watch-link>div {
    display: inline;
    position: relative;
}

.watch-link div:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    display: block;
    background: #666666;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.spotlight-slider:hover .watch-link>div:after {
    width: 100%;
    left: 0;
}

/* Jobs Section */


.sliderContent.job-slider {
    min-height: 320px;
}

.job-slider .location>* {
    font-size: 18px;
}

a.employer-logo img {
    width: 90px;
    height: auto;
}

.employer-logo {
    margin-bottom: 10px;
    height: 90px;
}

.employer-title {
    font-size: 18px;
    margin-bottom: 12px;
    font-weight: 400;
}

.employer-title a {
    color: black;
    text-decoration: none;
}

.employer-title:hover a {
    color: #D3A11F
}

.job-title {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 15px;
}

.left-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-wrap: wrap;
    width: min-content;
}

.left-badge button {
    margin-bottom: 5px;
}

.right-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background: black;
    font-size: 13px;
    font-weight: 300;
    padding: 6px 20px;
    border-radius: 50px;
}

.right-badge a {
    color: white;
    text-decoration: none;
}


/* Ad Agencies Section */

.open-jobs-btn {
    background: black;
    color: white;
    text-decoration: none;
    font-size: 15px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    margin: 20px auto 0;
    bottom: 40px;
    height: 55px;
    justify-content: center;
    align-items: center;
}

.open-jobs-btn:hover {
    background-color: #D9D9D9;
}

.open-jobs-btn:hover a {
    color: #D3A11F !important;
}

.open-jobs-btn a {
    color: white;
    text-decoration: none;
    width: 100%;
    flex: 1
}

.adagencies-slider .employer-title {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
}

/* Mentor Section */

.mentors-section {
    display: flex;
    justify-content: space-around;
    padding: 0 20px 20px;
    gap: 10px;
}

.mentor img {
    width: 100%;
    height: auto;
    filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
    transition-duration: 0.3s;
    border-style: solid;
    border-width: 4px 4px 4px 4px;
    border-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    position: relative;
}

.mentor {
    display: flex;
    transition: all 0.2s ease;
}

.publications-slider img:hover,
.mentor img:hover {
    transform: scale(1.3);
    z-index: 1;
}

@media (max-width:1200px) {
    .sliderContent {
        padding: 30px 5px;
    }

}

@media (max-width:768px) {
    .mentors-section {
        justify-content: center;
        flex-wrap: wrap;
    }

    .mentor {
        width: 30%;
    }

}

@media (max-width:400px) {
    .mentor {
        width: 75%;
        margin-bottom: 20px;
    }

    .location>* {
        font-size: 15px;
    }
}

/* Publications Section */
.publications-slider a {
    display: flex;
}

.sectionContent.publication-section {
    padding: 0px 10px;
}

.publications-slider {
    text-align: center;
}

img.publication-image {
    width: 100%;
    max-width: 410px;
    object-fit: cover;
    transition: all 0.2s ease;
}

.publication-section .row>div:nth-child(3n-1) .publications-slider a {
    justify-content: center;
}

.publication-section .row>div:nth-child(3n) .publications-slider a {
    justify-content: end;
}


/* Cities Section */

.city-banner-inner .bg-banner {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    background-size: cover;
}

.city-banner-inner {
    height: 623px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.city-banner-inner .inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding: 25px 30px;
    color: white;
}

.city-banner-inner .title {
    font-size: 18px;
    margin: 0 0 2px;
    position: relative;
    display: inline-block;
}

.city-banner-inner .number {
    font-size: 14px;
}

.city-banner-inner:hover .bg-banner {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.sectionContent.featured-cities {
    background: white;
    margin: 20px 10px;
    padding: 10px;
}

.job-banner-small .city-banner-inner {
    height: 300px;
}

.city-banner-inner {
    margin-bottom: 20px;
}

.city-banner-inner:hover .title:before {
    width: 100%;
    left: 0;
    background: white;
}

.city-banner-inner .title:before {
    content: "";
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 0;
    height: 1px;
    background-color: #202124;
    -webkit-transition: all .3s ease-in-out .3s;
    -o-transition: all .3s ease-in-out .3s;
    transition: all .3s ease-in-out .3s;
}

@media screen and (max-width:800px) {
    .city-banner-inner {
        max-height: 300px
    }
}

@media (max-width:768px) {

    .publication-section .publications-slider a {
        justify-content: center !important;
    }

}

/* Contact Section */

.contact-section {
    background: black;
    background-image: url('../assets/images/feather-contributor-bkgd.png');
    color: white;
    background-position: 100% 55px;
    background-repeat: no-repeat;
    background-size: 40% auto;
    padding: 100px;
}

.contact-section .title {
    font-size: 36px;
}

.contact-btn {
    display: flex;
    text-align: center;
    text-decoration: none;
    justify-content: center;
}

.contact-btn a {
    margin-top: 20px;
    text-decoration: none;
    background: white;
    color: black;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 15px 30px;
    -webkit-transition: background-color .3s ease;
    -o-transition: background-color .3s ease;
    transition: background-color .3s ease;
}

.contact-btn a:hover {
    background: #d3a11f;
}

.ticker {
    width: 100%;
    height: 60px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: white;
    color: black;
    display: flex;
    margin-top: 30px;
}

.ticker-text {
    display: flex;
    flex-direction: row;
    animation: ticker 30s linear infinite;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

@keyframes ticker {
    from {
        transform: translateX(100%);
        /* Start from the right edge */
    }

    to {
        transform: translateX(-100%);
        /* Move to the left edge */
    }
}


@media (max-width:768px) {
    .contact-section {
        padding: 60px 40px 60px 40px;
    }

    .contact-section .title {
        font-size: 27px;
    }
}

@media (max-width:500px) {
    .contact-section {
        background-position: bottom center;
        background-size: contain;
    }

    .contact-section .title {
        font-size: 32px;
        font-weight: 400;
    }

    .creative-section .sectionHeader {
        justify-content: center;
        gap: 0px 50px;
    }
}

.btn.btn-dark.btn-narrow {
    padding: 0px 5px;
    border-radius: 3px;
}

.wrapper {
    max-width: 100%;
    overflow: hidden;
    height: 60px;
    background-color: white;
    margin-top: 20px;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: marquee 30s linear infinite;
    height: 100%;
    line-height: 1;
    margin-top: 10px;
}

.marquee p {
    display: inline-block;
    font-family: 'Jost', Arial, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0;
    margin-top: 5px;
    position: relative;
}

.marquee p .bullets-container {
    margin: 0px 100px;
    position: relative;
}

.marquee p .bullets {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 900;
    position: absolute;
    left: -90px;
    top: 5px;
}

.marquee p .h1 {
    font-size: calc(1.375rem + 1.5vw);
    margin-right: 10px;
}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-50%, 0, 0);
    }
}