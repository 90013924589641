.profile-sidebar {

    .sidebar-item {
        padding: 30px;
        background: #eee !important;
        border-radius: 8px;
        // width: 100%;
    }

    .item {
        display: flex;
        gap: 15px;
        margin-bottom: 25px;
    }


    .text {
        font-size: 16px;
        font-weight: 500;
        color: #202124;
    }

    .value {
        font-size: 20px;
        overflow-wrap: break-word;
    }

    .title {
        margin-bottom: 18px;
    }

    .video-section video {
        width: 100%;
        border-radius: 5px;
    }

    .btn-dark:hover {
        background-color: rgba(0, 0, 0, 0.07);
        border-color: transparent;

        a {
            color: #fff !important;

        }
    }
}