.page-single-mentor {
    .title {
        text-align: center;
        font-size: 60px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .subtitle {
        text-align: center;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 20px;
    }

    .box {
        border: 1px solid white;
        text-align: center;
        position: relative;
        height: 220px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        transform-style: preserve-3d;
        transition: all 1.3s ease;
        box-shadow: 2px 2px 10px #afafaf;

        &.flip.active {
            transform: rotateY(180deg);
        }
    }

    .main-title {
        font-size: 3rem;
        font-family: 'Alta';
        line-height: 45px;
        font-weight: 600;
        width: 100%;
        text-shadow: 1px 0px white;
    }

    .box-link {
        position: absolute;
        right: 10px;
        bottom: 17px;
    }

    .box-link .link {
        color: white;
        border: 1px solid white;
        border-radius: 10px;
        height: 20px;
        width: 20px;
        display: flex;
        padding: 0px;
    }

    .title-small {
        margin-top: 10px;
        width: 80%;
        position: absolute;
        bottom: 17px;
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .page-single-mentor .box:hover {
        transform: rotateY(180deg);
    }

    .flip-card-back img {
        width: 100%;
        height: 100%;
    }

    .flip-card-back {
        transform: rotateY(180deg);
    }

    .flip-card-front {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }

    @media (max-width:1300px) {
        .main-title {
            font-size: 2rem;
        }
    }

    @media (max-width:900px) {
        .main-title {
            font-size: 1.5rem;
        }
    }

    @media (max-width:400px) {
        .title {
            font-size: 38px;
        }
    }
}