.post-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 60%;
    background: white;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
    min-height: 80%;
    display: flex;
    flex-direction: column;

    .postmodal-header {
        display: flex;
        align-items: center;
        gap: 10px;
    }


    .postmodal-body {
        margin-top: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        [contenteditable="true"] {
            flex: 1;

            &:focus-visible {
                outline: none;
            }

            &:empty:before {
                content: attr(placeholder);
                display: block;
                color: #858585;
                cursor: text;
            }
        }

        .toggle-emo {
            display: inline-block;
            cursor: pointer;
        }

        .post-options {
            margin-top: 20px;
            position: relative;
            align-items: center;
            flex-wrap: wrap;

            .item {
                background: #f3f2f0;
                margin-right: 10px;
                padding: 10px;
                font-size: 20px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                text-align: center;
                cursor: pointer;
            }

            .item:hover {
                box-shadow: rgba(140, 140, 140, 0.2) 0px 0px 0px 1px, rgba(0, 0, 0, 0.3) 0px 4px 4px 0px;
            }

            .post-attachments {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .post-attachment {
                    position: relative;

                    img {
                        width: 75px;
                        min-width: 75px;
                        max-width: 75px;
                        height: 75px;
                        min-height: 75px;
                        max-height: 75px;
                        object-fit: cover;
                        border: 2px solid var(--color-primary);
                        margin: 5px
                    }

                    video {
                        width: 75px;
                        min-width: 75px;
                        max-width: 75px;
                        height: 75px;
                        min-height: 75px;
                        max-height: 75px;
                        object-fit: cover;
                        border: 2px solid var(--color-primary);
                        margin: 5px
                    }

                    svg {
                        font-size: 24px;
                        font-weight: 900;
                        color: black;
                        cursor: pointer;
                        position: absolute;
                        top: -6px;
                        right: -6px;
                    }

                    svg:hover {
                        font-size: 24px;
                        color: var(--color-primary);
                    }
                }
            }
        }


        aside.EmojiPickerReact.epr-main {
            position: absolute;
            top: -10px;
        }

    }


    .postmodal-footer {

        .btn-post {
            background: #d3a11f;
            color: white;
            padding: 5px 20px;
            border-radius: 20px;
            min-width: max-content;
        }

        .postmodal-action {
            text-align: right;
        }

        .btn-post:hover {
            background-color: #b98c16;
            color: white;
        }
    }

}

.post-item {
    .post-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        img {
            width: 125px;
            min-width: 125px;
            max-width: 125px;
            height: 125px;
            min-height: 125px;
            max-height: 125px;
            object-fit: cover;
            border: 2px solid var(--color-primary);
            margin: 5px
        }

        video {
            width: 100%;
            max-height: 320px;
            object-fit: cover;
            margin: 5px;
            border: 2px solid var(--color-primary);
        }
    }
}

.postmodal-input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
}

.postmodal-input:focus {
    border: 1px solid darkgray;
}