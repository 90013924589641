.add-note-modal {
   
    width: 600px;
    background: white;
    padding: 40px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .addnote-header {
        display: flex;
        align-items: center;
        gap: 10px;
    }


    .addnote-body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        textarea,
        input {
            color: #000000;
            background-color: #F6F6F6;
            padding: 15px 15px 15px 15px;
            resize: none;
            height: auto;
            font-size: 20px;

            &::placeholder {
                color: #999999;

            }
        }

        label {
            font-size: 20px;
        }

        .notes-list-item {
            margin: 30px 0;
        }
        .note-item {
            padding: 15px;
            margin: 15px 0;
            border-radius: 15px;
            border-bottom: 3px solid #f6f6f6;
        }
    }

  

}