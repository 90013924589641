.showToggle {
    transition: all 0.3s ease;
    position: absolute;
    top: 60%;
    right: 22px;
    cursor: pointer;
    color: var(--color-primary);
    font-size: 19px;
    line-height: 0px;
}

.showToggle:hover {
    color: black;
}