.agency-page-my-resume {
    .title {
        background: #f6f6f6;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 48px;
        cursor: pointer;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        margin: 0;
        font-size: 21px;
    }

    .close-btn,
    .toggle-btn {
        position: absolute;
        top: 22px;
        cursor: pointer;
    }

    .repeater-field {
        position: relative;
        margin-bottom: 15px;
    }

    .close-btn {
        color: #a00;
        padding: 0px 10px;
    }

    .toggle-btn {
        right: 10px;
        color: black;
    }

    .field-dropdown {
        // display: none;

        .row {
            margin: 15px 0;
        }
    }

    .portfolio_item {
        width: 150px;
        margin-right: 10px;
        display: inline-block;
        height: 150px;
        vertical-align: middle;
        border-radius: 10px;
        overflow: hidden;
    }
}