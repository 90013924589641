.filters {
    background: #eee;
    border-radius: 8px;
    padding: 26px 30px 60px;
    margin-bottom: 30px;
}

.jobs-filter-title {
    color: #D3A11F;
    font-size: 24px;
    margin-bottom: 18px;
}

.filter-label {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 500;
}

.filter-item {
    margin-bottom: 30px;
}

.job-item {
    -webkit-transition: all 0.35s ease-in-out 0s;
    -o-transition: all 0.35s ease-in-out 0s;
    transition: all 0.35s ease-in-out 0s;
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    border: 1px solid #ECEDF2;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 30px;
}

.job-item .employer-logo {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.job-item .employer-logo img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.job-list-content {
    float: left;
    display: block;
}

.job-item .inner-left {
    flex: 1;
    display: flex;
    gap: 20px;
}

.job-item .job-title a {
    color: black;
    text-decoration: none;
}

.job-item .job-title {
    font-size: 36px;
    font-weight: 400;
    margin: 0;
}

.job-category a,
.job-deadline {
    text-decoration: none;
    color: #696969;
    font-size: 20px;
}

.star-badge {
    background: white;
    border-radius: 50px;
    padding: 5px;
    box-sizing: content-box;
    color: white;

    &.featured {
        background-color: #daa520;
    }

    &.onsite {
        background-color: #000000;
    }

    &.hybrid {
        background-color: #090070;
    }

    &.remote {
        background-color: #17bd81;
    }

    &.urgent {
        background-color: #f40606;
    }

}

.job-metas {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    >div {
        width: 100%;
    }
}

.category-job,
.job-deadline {
    // flex: 50%;
}

.category-job {
    margin-right: 10px
}

.btn-apply {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: black;
    padding: 10px 30px;
    font-size: 1.1rem;
    border-radius: 8px;
    margin: 0 10px;
    background: black;
    color: var(--color-primary);
}

.btn-apply:hover,.btn-apply.active {
    background-color: var(--color-primary);
    color: black;
    border-color:transparent
}

.job-type {
    line-height: 26px;
    display: inline-block;
    color: var(--superio-theme-color);
    background: black;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    padding: 1px 20px;
    font-size: 13px;
    margin-right: 15px;
}

.type-job {
    text-decoration: none;
    color: white;
    font-size: 13px;
}

.type-job:hover {
    color: var(--color-primary);
}


.form-check-input:checked[type=radio] {
    background-color: black;
    border-color: black;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    background-size: 10px;
}

input.form-check-input {
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.btn-filter {
    background-color: #777777;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 10px 30px;
    transition: all 0.2s ease-in;
}

.btn-filter:hover {
    color: white;
    background: var(--color-primary);
    border: 1px solid black;
}

.job_notification {
    margin-top: 80px;
}


.notif-input-box {
    margin-top: 20px;
}

.job-alert-button {
    margin-top: 20px;
}

.alert-btn {
    border: 1px solid black;
    background: black;
    width: 100%;
    color: white;
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.alert-btn:hover {
    background: white;
    color: black;
    border-color: black;
}


.job_notification {
    margin-top: 80px;
}


.notif-input-box {
    margin-top: 20px;
}

.job-alert-button {
    margin-top: 20px;
}

.alert-btn {
    border: 1px solid black;
    background: black;
    width: 100%;
    color: white;
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.alert-btn:hover {
    background: white;
    color: black;
    border-color: black;
}

.results-filter-wrapper .inner {
    background: #eee;
    padding: 10px 10px 0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    overflow: hidden;
    clear: both;
    display: -webkit-flex;
    display: flex;
}

.results-filter-wrapper {
    margin-bottom: 20px;
}

.results-filter-wrapper .title {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 15px;
}

.results-filter-wrapper .results-filter {
    padding: 0;
    margin: 0;
    list-style: none;
}

.results-filter-wrapper .results-filter li {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.results-filter-wrapper .results-filter a {
    display: inline-block;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 4px 15px;
    color: #696969;
    background: #F6F6F6;
    font-size: 20px;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    text-decoration: none;
    text-transform: capitalize;
}

.results-filter-wrapper .results-filter .close-value {
    padding-right: 5px;
    color: #e44343;
    font-size: 15px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.results-filter-wrapper .inner>a {
    color: #E5174A;
    font-size: 20px;
    margin-top: 5px;
    display: inline-block;
    margin-left: auto;
    padding-right: 10px;
    text-decoration: none;
    width: 100px;
}

.results-filter-wrapper .results-filter a:hover {
    color: #E5174A;
}

.jobs-alert-ordering-wrapper {
    margin: 30px 0px;
}

.results-count {
    font-size: 20px;
}

.job-category.with-icon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.job-apply-modal {
    .btn-resume {
        width: 48%;
        border: 0;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        padding: 5px 10px;
        overflow: hidden;
        background-color: #d9d9d9;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -o-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;

        &.active {
            background: black;
            color: white;
        }

        &:hover {
            background: var(--color-primary);
            color: white;
        }
    }

    .label-can-drag {
        border: 2px dashed rgba(25, 103, 210, 0.15);
        text-align: center;
        padding: 30px;
        cursor: pointer;
        font-size: 20px;
        color: black;
    }

    .upload-file-btn-wrapper {
        margin-bottom: 30px;
    }

}


@media (max-width:400px) {
    .job-item {
        padding: 15px;
    }

    .job-item .job-title {
        font-size: 25px;
    }

    .job-category a,
    .job-deadline {
        font-size: 15px;
    }

    .job-type {
        margin: 5px 15px 5px 0px;
    }

    .btn-apply {
        font-size: 16px;
    }
}