.site-logo img {
    width: 93%;
}

.btn-showmenu>* {
    background-color: #202124;
    display: inline-block;
    height: 2px;
    width: 20px;
    margin: 3px 0;
}

.btn-showmenu .inner2 {
    width: 25px;
}

.btn-showmenu .inner3 {
    width: 15px;
}

a {
    color: var(--color-primary);
}

a:hover {
    color: black;
}

a.active>.menu-link-btn {
    color: var(--color-primary);
}

.nav-item {
    display: inline-block;
}

.right-menu .post-job-link {
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: black;
    padding: 8px 10px !important;
    font-size: 1.1rem;
    border-radius: 8px;
    margin: 0 10px;
}

.right-menu .post-job-link:hover {
    background: black;
    color: var(--color-primary);
}

.right-menu .login-btn:hover {
    border: 2px solid black;
    background: var(--color-primary);
}

.right-menu .login-btn {
    padding: 3px 10px 3px 10px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 8px;
    border: 2px solid white;
    text-transform: none;
}

/* Drawer Styles */
.drawer-menu-link {
    color: rgb(151, 151, 151);
    font-size: 17px;
    padding: 12px 20px;
}

.drawer-menu-link:hover {
    color: white;
}

.drawer-box {
    padding: 20px;
}

.drawer-menu-link {
    color: rgb(151, 151, 151) !important;
    font-size: 17px;
    padding: 12px 20px;
}

.drawer-menu-link:hover {
    color: white;
}

.drawer-box {
    padding: 20px;
}

.social-box {
    padding: 0 20px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.drawer-box .social {
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.5s ease;
    font-size: 21px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drawer-box .social:hover {
    background: var(--color-primary);
    color: white;
}


.has-children:hover .dropdown-menu {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    left: 0;
    overflow-y: auto;
}

.has-children:hover .dropdown-menu:not(.logged-in-dropdown) {
    border-top: 2px solid black;
    border-radius: 0;
}

.dropdown-menu {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -o-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    -webkit-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    left: 0;
}

ul.dropdown-list {
    list-style-type: none;
    min-width: 200px;
    padding: 0;
    padding-top: 15px;
}

ul.dropdown-list li a {
    text-decoration: none;
    font-size: 20px;
    color: black;
    width: 100%;
    padding: 7px 30px;
    display: block;
}

ul.dropdown-list li a:hover {
    color: var(--color-primary)
}

.has-children {
    position: relative;
}

ul.dropdown-list:before {
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent black;
    content: '';
    position: absolute;
    bottom: 100%;
    left: 40px;
}

.logged-in-menu {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--color-primary);
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 300;
    cursor: pointer;
}

.logged-in-menu:hover {
    color: black;
}

.logged-in-dropdown {
    left: auto !important;
    right: 0px;
    top: 65px;
    padding: 15px;
    max-height: 450px;
    overflow: hidden;
}

.logged-in-dropdown .dropdown-list:before {
    content: none;
}

.logged-in-dropdown ul li a {
    padding: 10px 20px;
    border-radius: 8px;
}

.logged-in-dropdown ul li:hover a {
    background: var(--color-secondary);
}

.logged-in-dropdown ul {
    padding-top: 0;
    margin-bottom: 0px;
}

.btn.btn-dark {
    border: 2px solid black;
    background-color: black !important;
    color: white !important;
}

.btn.btn-dark:hover {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
}

.menu-item {
    position: relative;
}

.menu-item .count {
    position: absolute;
    right: 10px;
}

.center-page {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    min-width: 250px;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    user-select: none;
}

.btn.btn-dark.btn-selected {
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary) !important;
}

#reviews {
    padding: 20px 0px;
}

.rating-submitted {
    background-color: var(--color-primary);
    color: #000;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 10pt;
    display: flex;
    column-gap: 5px;
}

.rating-submitted svg {
    margin-top: 2px;
}

.btn-review {
    font-size: 12px;
    padding: 5px 15px;
}

textarea.form-control:focus {
    border: 1px solid gray;
}

@media (max-width: 539px) {
    .auth-modal .auth-body ul.role-tabs.nav.nav-tabs li {
        flex: unset !important;
        /* For Login / Register Buttons on separate rows on small screens */
    }
}

.scroll-button-span {
    background-color: #000;
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: -1;
}